body {
  font-family: "Roboto", sans-serif;
}

p {
  color: rgb(127, 127, 127);
}

.filterBottom {
  background: linear-gradient(to bottom, #9c00d3, #5b3de8);
}

.productlist {
  h3 {
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: rgb(66 66 66 / var(--tw-text-opacity));
  }

  h3:hover {
    color: #5b3de8;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 19px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 4px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #221f1f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17.5px;
  width: 17px;
  left: -1px;
  bottom: -0.7px;
  background-color: #f1f1f1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #38a626;
  opacity: 60%;
}

input:checked+.slider:before {
  transform: translateX(19px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.backdrop_device {
  background-color: rgba(78, 76, 76, 0) !important;
}

.SectionHeader {
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-family: "Roboto", sans-serif;
  color: #5b3de8;
  padding-bottom: 12px;
}

.popupHeader {
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-family: "Roboto", sans-serif;
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  color: #424242;
}

.submitButton {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  --tw-shadow-color: rgb(33 33 33 / 0.1);
  --tw-shadow: var(--tw-shadow-colored);
  -tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  letter-spacing: 0.05em;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  padding: 0.75rem 1.5rem;
  background-color: #5b3de8;
  border-radius: 50px;
  width: 200px;
  margin-top: 20px;
}

.filterbutton {
  padding: 8px 20px;
  border-color: #5b3de8;
  background-color: #f0ecff;
  color: #5b3de8;
  border-radius: 50px;
  border: 1px solid;
  font-size: 0.875rem;
}

.formStyle {
  label {
    color: #757575;
    letter-spacing: 0.025em;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5rem;
  }

  .custom-label {
    color: #757575;
    letter-spacing: 0.025em;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5rem;
  }

  input {
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding: 0.75rem;
    border-width: 1px;
    border-radius: 0.375rem;
    width: 100%;
    border-color: #cdcdcd;
    margin-top: 2px;
  }

  select {
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding: 0.89rem;
    border-width: 1px;
    border-radius: 0.375rem;
    width: 100%;
    border-color: #cdcdcd;
    margin-top: 2px;
  }
}

textarea {
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 0.75rem;
  border-width: 1px;
  border-radius: 0.375rem;
  width: 100%;
  border-color: #cdcdcd;
  margin-top: 2px;
}

.scrollbar {
  overflow-y: scroll;
}

.scrollbar::-webkit-scrollbar {
  width: 2px;
  height: 1px;
}

/* gig style */
.gigmaintitle {
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  line-height: 1.75rem;
  color: rgb(66 66 66 / var(--tw-text-opacity));
  padding-bottom: 20px;
}

.gigsingletab {
  .gigsingleinnertab {
    letter-spacing: 0.025em;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    text-align: center;
    padding: 0.75rem;
    --tw-border-opacity: 1;
    border-color: rgb(158 158 158 / var(--tw-border-opacity));
    border-width: 1px;
    border-radius: 0.375rem;
    cursor: pointer;
  }
}

/* gig style */

/* skill button pills */
.skillpills {
  p {
    color: #5235cd;
    padding: 0.125rem 0.75rem;
    background-color: #c6caff;
    border-radius: 9999px;
    font-size: 13px;
  }
}

/* skill button pills */
/* dashboard card style */
.DashboardCard {
  .CardStats {
    box-shadow: 0 0 10px #0000001a;
    padding: 1.25rem;
    background-color: #fff;
    border-radius: 0.5rem;
  }

  .comment_class {
    box-shadow: 0 0 10px #0000001a;
    background-color: white;
    border-radius: 0.5rem;
  }

  .CardStatsLarge {
    box-shadow: 0 0 10px #0000001a;
    background-color: #fff;
    border-radius: 0.5rem;
  }

  .cardValue {
    color: #5b3de8;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 2.25rem;
  }

  .cardValueRight {
    color: #5b3de8;
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 2.25rem;
    text-align: right;
  }

  .cardValue {
    color: #5b3de8;
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 2.25rem;
  }

  .cardTitleRight {
    color: #424242;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.6px;
    text-align: right;
  }

  .cardTitle {
    color: #424242;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.6px;
  }

  .cardTitle {
    color: #424242;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.6px;
  }
}

.product-card {
  &:hover .product-link {
    color: #5b3de8;
    text-decoration: underline;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}



/* styles added for small screens */
@media (max-width: 767px) {




  body {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

  .SectionHeader,
  .popupHeader {
    font-size: 20px;
    /* Decrease header size */
    line-height: 2rem;
  }

  .submitButton {
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    width: 160px;
    /* Reduce button size */
  }

  .filterbutton {
    font-size: 0.75rem;
    padding: 6px 15px;
  }

  .formStyle label,
  .formStyle .custom-label {
    font-size: 14px;
  }

  .formStyle input,
  .formStyle select,
  textarea {
    padding: 0.6rem;
    font-size: 14px;
  }

  .switch {
    width: 30px;
    height: 16px;
  }

  .slider:before {
    width: 15px;
    height: 15px;
  }

  input:checked+.slider:before {
    transform: translateX(16px);
  }

  .gigmaintitle {
    font-size: 22px;
  }

  .gigsingletab .gigsingleinnertab {
    font-size: 14px;
    padding: 0.6rem;
  }

  .skillpills p {
    font-size: 12px;
    padding: 0.1rem 0.5rem;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
}