@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Roboto", sans-serif !important;
  }
  .sidebar,
  .information-title,
  .piechart {
    font-family: "Roboto", sans-serif !important;
  }
  .information-value {
    font-family: "Roboto", sans-serif !important;
  }

  .scrollingbar::-webkit-scrollbar {
    display: none;
  }

  .top-header,
  .information-value {
    font-family: "Roboto", sans-serif !important;
  }
  #tablebody:hover {
    background-color: rgba(187, 185, 185, 0.267) !important;
  }
  #actualPostion:hover {
    background-color: rgba(187, 185, 185, 0.267) !important;
  }
  #actualPostion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.5rem;
  }
  .shadow_box {
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.2) !important;
  }
  .custom-input:focus {
    border: 1px solid #5b3de8 !important;
    outline: 1px solid #5b3de8; /* Change the border color when in focus */
  }
  .custom-input {
    border: 1px solid #b0bec5;
  }
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: "Roboto", sans-serif !important;
}

/* ::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #9c00d3, #5b3de8);
  border-radius: 15px;
} */
/* ::-webkit-scrollbar-track {
  background-color: #c2bfbf;
} */

.labelLineHeight
  .peer:placeholder-shown
  ~ .peer-placeholder-shown\:leading-\[4\.1\] {
  line-height: 4.75 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"] {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* .select:focus ~ .arrow{
  rotate: 180deg;
} */
.text-area {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
}

input[type="radio"]:checked::after {
  background: linear-gradient(to top, #5b3de8, #9c00d3);
  width: 12px;
  height: 12px;
  content: "";
  position: relative;
  border-radius: 50%;
  top: -3px;
  left: 3px;
  display: inline-block;
}
.user_posts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.option:hover .more_option {
  display: block;
}
.curve-card .curve-box {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #6ef09a, #37b29d);
  border-radius: 0 0 50% 50%;
  overflow: hidden;
}
.bordring {
  position: relative;
}
.bordring::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  left: 0;
  bottom: 11px;
  background: lightgray;
}
.bordring::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  right: 0;
  top: 11px;
  background: lightgray;
}
.login-shadow {
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.3);
}
.login-bg {
  background: linear-gradient(to right, #5b3de8, #9c00d3);
}
.login-background {
  background-image: url("../src/assets/image/authbg.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.box-shadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.griding {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.cardstyle {
  .cardValue {
    font-size: 1.875rem /* 30px */;
    line-height: 3.25rem /* 36px */;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    --tw-text-opacity: 1;
    color: rgb(82 53 205 / var(--tw-text-opacity));
  }
  .cardTitle {
    --tw-text-opacity: 1;
    color: rgb(66 66 66 / var(--tw-text-opacity));
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem /* 16px */;
    line-height: 1.5rem /* 24px */;
    font-weight: 400;
  }
}
